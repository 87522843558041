import { NgIf } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, Renderer2 } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, tap } from 'rxjs';

import { LandingPages, LandingPageValue } from '@@core/models/landing-page.model';
import { FeatureService } from '@@core/services/features.service';
import { LaunchDarklyService, LDFeatureKey } from '@@core/services/launch-darkly.service';
import { PermissionsService } from '@@settings/services/permissions.service';
import { HeaderComponent } from '@@shared/header';
import { selectUserPreferencesState, UserPreferenceKey } from '@@shared/user-preferences';
import * as UserPreferencesActions from '@@shared/user-preferences/actions/user-preferences.actions';

@Component({
	selector: 'sl-authenticated-layer',
	templateUrl: './authenticated-layer.component.html',
	styleUrl: './authenticated-layer.component.scss',
	standalone: true,
	imports: [
		HeaderComponent,
		RouterModule,
		NgIf
	]
})
export class AuthenticatedLayerComponent implements OnInit {
	readonly ldInitializedSignal$ = toSignal(inject(LaunchDarklyService).ldInitialized$);
	readonly #ldInitialized$ = inject(LaunchDarklyService).ldInitialized$;

	readonly #destroyRef = inject(DestroyRef);
	readonly #router = inject(Router);
	readonly #permissionsService = inject(PermissionsService);
	readonly #featureService = inject(FeatureService);
	readonly #renderer = inject(Renderer2);
	readonly #route = inject(ActivatedRoute);
	readonly #store = inject(Store);

	constructor() {
		this.#ldInitialized$
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				filter(isInitialized => !!isInitialized),
				distinctUntilChanged(),
				tap(() => this.#handleLdAndPageInit())
			)
			.subscribe();
	}

	ngOnInit(): void {
		this.#renderer.removeClass(document.body, 'unauthenticated-page');
		this.#loadUserPreferences();
	}

	#handleLdAndPageInit(): void {
		this.#initPage();
	}

	#initPage(): void {
		this.#refreshUnassignedApplicationsCount();
		this.#manageStateNavigation();
	}

	#loadUserPreferences(): void {
		this.#store.select(selectUserPreferencesState)
			.pipe(
				take(1),
				tap(userPreferencesStore => {
					if (!userPreferencesStore.loaded) {
						this.#store.dispatch(UserPreferencesActions.getUserPreference());
					}
				})
			).subscribe();
	}

	#manageStateNavigation(): void {
		let state = this.#route.snapshot.queryParamMap.get('state');
		if (state) {
			state = decodeURIComponent(state);
		}

		if (state) {
			if (state === '/') {
				this.#goToDefaultLandingPage(); // redirect root URL to the default
			} else if (/^\//.test(state)) {
				void this.#router.navigateByUrl(state); // redirect if 'state' exists and is of the pattern "/..." - a valid redirect to path
			} else {
				this.#goToDefaultLandingPage(); // otherwise redirect to the default
			}
		} else {
			if (/^\/?$/.test(location.pathname)) {
				this.#goToDefaultLandingPage();
			}
		}
	}

	#refreshUnassignedApplicationsCount(): void {
		this.#featureService.isFeatureOn(LDFeatureKey.PERMISSIONS)
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				filter(isOn => !!isOn),
				take(1),
				tap(() => this.#permissionsService.refreshUnassignedApplicationsCount())
			).subscribe();
	}

	#goToDefaultLandingPage(): void {
		this.#store.select(selectUserPreferencesState)
			.pipe(
				filter(store => store.loaded),
				take(1),
				tap(userPreferencesStore => {
					const landingPage = userPreferencesStore.values[UserPreferenceKey.LandingPage];

					void this.#router.navigateByUrl(LandingPages[landingPage]?.route || LandingPages[LandingPageValue.Dashboard].route);
				})
			).subscribe();
	}
}
