import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnInit
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
	ActivatedRoute,
	Data,
	NavigationEnd,
	Router,
	RouterModule
} from '@angular/router';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { combineLatest, map, take } from 'rxjs';

import { getHideSuperuserMessagesFlag, isChromeBrowser, toggleHideSuperuserMessagesFlag } from '@@core/helpers/core.helper';
import { UserRolePermission } from '@@core/models/auth/auth-model';
import { UserPilotExperience } from '@@core/models/userpilot.model';
import { BrowserExtensionService } from '@@core/services/browser-extension.service';
import { LDFeatureKey } from '@@core/services/launch-darkly.service';
import { LoaderService } from '@@core/services/loader.service';
import { UserPilotService } from '@@core/services/user-pilot.service';
import { PermissionsService } from '@@settings/services/permissions.service';
import { AvatarComponent } from '@@shared/avatar';
import {
	ConfirmationDialogData,
	ConfirmationDialogNewComponent,
	GenericDialogComponent
} from '@@shared/dialog';
import { JoinPipe } from '@@shared/join-pipe';
import { LetDirective } from '@@shared/let-directive';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';
import * as GlobalStoreActions from '@@shared/stores/app-stores/global-store/actions/global-store.actions';
import { selectLoginAsUserId } from '@@shared/stores/app-stores/global-store/selectors/global-store.selectors';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';
import { ConfigurationStore } from '@@shared/stores/configuration-store/stores/configuration.store';
import { ManualTestLauncherComponent } from '@@widgets/manual-test/components/manual-test-launcher/manual-test-launcher.component';
import { ManualTestService } from '@@widgets/manual-test/services/manual-test.service';

import { HeaderAnalyticsService } from '../../core/services/analytics/header-analytics.service';
import { FeatureService } from '../../core/services/features.service';
import { AuthService } from '../../features/auth/services/auth.service';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { HeaderButtonComponent } from './header-button/header-button/header-button.component';

@Component({
	selector: 'sl-header',
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		MatProgressBarModule,
		MatToolbarModule,
		MatButtonModule,
		MatIconModule,
		TagModule,
		RouterModule,
		MatTooltipModule,
		MatBadgeModule,
		AvatarComponent,
		MatDividerModule,
		JoinPipe,
		ChangePasswordDialogComponent,
		LetDirective,
		MatMenuModule,
		ManualTestLauncherComponent,
		ButtonModule,
		HeaderButtonComponent
	]
})
export class HeaderComponent implements OnInit {
	dialogConfig: MatDialogConfig = new MatDialogConfig();
	hideSuperuserMessages = getHideSuperuserMessagesFlag();

	readonly customTabsSignal$ = inject(ConfigurationStore).customTabs;
	readonly impersonatedUser$ = inject(Store).select(selectLoginAsUserId);
	readonly ssoMessageSignal$ = inject(ConfigurationStore).ssoMessage;
	readonly isUserSealightsAdminSignal$ = inject(AuthStore).isSealightsAdminUser;
	readonly isDevOpsRoleSignal$ = inject(AuthStore).isDevopsUser;
	readonly isUserSealightsAdmin$ = toObservable(this.isUserSealightsAdminSignal$);
	readonly isDevOpsRole$ = toObservable(this.isDevOpsRoleSignal$);
	readonly isManualTestShown$ = inject(ManualTestService).isOverlayShownObservable$;
	readonly isManualTestActive$ = inject(ManualTestService).isActiveObservable$;
	readonly isUsersAndPermissionsFeatureActiveSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.PERMISSIONS));
	readonly isCiMonitorFeatureActiveSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.CI_MONITOR));
	readonly isLabMonitorFeatureActiveSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.LABS));
	readonly isPublicApiFeatureActiveSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.PUBLIC_API));
	readonly isCustomSupportFeatureActiveSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.CUSTOM_SUPPORT));
	readonly isLiveLabsEnabledSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.LIVE_LABS_MONITOR));
	readonly isTestManagementTabVisibleSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.TEST_MANAGEMENT_TAB_VISIBLE));
	readonly userNameSignal$ = inject(AuthStore).fullName;
	readonly userSignal$ = inject(AuthStore).user;
	readonly user$ = toObservable(this.userSignal$);
	readonly avatarUserDataSignal$ = inject(AuthStore).avatarInfo;
	readonly coverageDashboardFeatureActiveSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.DASHBOARD_V2));
	readonly dashboardFeatureActiveSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.DASHBOARD));
	readonly isBusy$ = inject(LoaderService).isBusy$;
	readonly isCockpitAllowedSignal$ = computed(() => UserRolePermission.COCKPIT.includes(this.userSignal$()?.role));
	readonly permissionsState$ = inject(PermissionsService).stateObservable$;
	readonly isManualTestRunnerFeatureActiveSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.MANUAL_TEST_RUNNER));
	readonly isTroubleshootingFeatureActiveSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.TROUBLESHOOTING));
	readonly isCustomTabsFeatureActiveSignal$ = toSignal(combineLatest([
		inject(FeatureService).isFeatureOn(LDFeatureKey.CUSTOM_TABS),
		this.user$
	]).pipe(map(
		([featureActive, userDetails]) => featureActive && UserRolePermission.CUSTOM_TABS.includes(userDetails?.role)
	)));
	readonly isAuditLogsFeatureAvailableSignal$ = computed(() => {
		if (this.isUsersAndPermissionsFeatureActiveSignal$() && !this.isUserSealightsAdminSignal$()) {
			return this.#permissionsService.userHasPermission('up-management');
		}

		return true;
	});

	readonly isCustomSupportMessageSignal$ = inject(ConfigurationStore).isCustomSupportMessageEnabled;

	readonly isProductTourVisible$ = inject(UserPilotService).isProductTourVisible$;
	readonly showDataProgressSignal$ = inject(ConfigurationStore).showDataProgress;
	readonly displayLiveLabsTabSignal$ = computed(() =>
		this.isLiveLabsEnabledSignal$() && !(this.isUserSealightsAdminSignal$() || this.isDevOpsRoleSignal$())
	);
	readonly isDashboardV2ActiveSignal$ = toSignal(
		inject(FeatureService).isFeatureOn(LDFeatureKey.DASHBOARD_V2)
	);

	readonly isSsoUserSignal$ = inject(AuthStore).isSSOUser;
	readonly originalUserSignal$ = inject(AuthStore).originalUser;
	readonly originalUserDetailsSignal$ = computed(() => {
		if (this.originalUserSignal$()) {
			return [
				`${this.originalUserSignal$()?.name} ${this.originalUserSignal$()?.family_name} (${this.originalUserSignal$()?.email})`,
				`Logged in as ${this.userSignal$()?.name} ${this.userSignal$()?.family_name} (${this.userSignal$()?.email})`
			];
		}

		return null;
	});
	readonly userTooltipSignal$ = computed(() => `${this.userSignal$()?.name} ${this.userSignal$()?.family_name} (${this.userSignal$()?.email})`);

	// Services declaration
	readonly #router = inject(Router);
	readonly #activatedRoute = inject(ActivatedRoute);
	readonly #authService = inject(AuthService);
	readonly #permissionsService = inject(PermissionsService);
	readonly #featureService = inject(FeatureService);
	readonly #userPilotService = inject(UserPilotService);
	readonly #manualTestService = inject(ManualTestService);
	readonly #headerAnalyticsService = inject(HeaderAnalyticsService);
	readonly #browserExtensionService = inject(BrowserExtensionService);
	readonly #dialog = inject(MatDialog);
	readonly #applicationConfig = inject(APP_CONFIG);
	readonly #userPilotFeatureSignalIsOn$ = toSignal(this.#featureService.isFeatureOn(LDFeatureKey.USER_PILOT));
	readonly #store = inject(Store);
	readonly #manualTestExtensionSignalIsOn$ = toSignal(this.#featureService.isFeatureOn(LDFeatureKey.MANUAL_TEST_EXTENSION));

	ngOnInit(): void {
		this.#router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.#onRouteChange();
			}
		});

		this.#onRouteChange();
	}

	showManualTest(): void {
		if (this.#manualTestExtensionSignalIsOn$()) {
			this.#manualTestService.showOverlay = true;

			return;
		}

		void this.doManualTest();
	}

	async doManualTest(): Promise<void> {
		const promotion = `
			Running manual test from SeaLights Chrome extension will allow SeaLights to collect rich test metrics.
			Consequently, SeaLights will provide more accurate list of recommended tests in future builds.
			<br><br>
			Higher accuracy of recommended tests reduces tests and saves time.
		`;
		const promotionButNotInstalled = `
			${promotion}
			<br><br>
			SeaLights Chrome extension appears to be unavailable from your workstation.
		`;
		const promotionButNotChrome = `
			${promotion}
			<br><br>
			<b>Note:</b> SeaLights Chrome extension is only available in Chrome browser.
		`;
		const confirmationDialogData = {
			title: 'Manual Test',
			content: promotion,
			cancelButtonCaption: 'Continue here',
			showCancelButton: true
		} as ConfirmationDialogData;
		let confirmHandler = (): void => { };
		let isInstalled = false;
		let isStorePageAvailable = false;

		await this.#browserExtensionService
			.isInstalled()
			.then(async (found: boolean) => {
				isInstalled = found;
				if (!isInstalled) {
					isStorePageAvailable =
						await this.#browserExtensionService.isStorePageAvailable();
				}
			});

		if (isChromeBrowser()) {
			if (isInstalled) {
				confirmationDialogData.confirmButtonCaption =
					'Go with the extension';
				confirmHandler = (): void => {
					this.#browserExtensionService.requestToken();
					setTimeout(() => {
						if (
							!this.#browserExtensionService.tokenData?.token ||
							this.#browserExtensionService.tokenData?.server !==
							this.#applicationConfig.uri.apiBaseUrl
						) {
							void this.#browserExtensionService.setToken();
						}
					}, 500);
					setTimeout(() => {
						this.#browserExtensionService.startManualTest();
					}, 2000);
				};
			} else if (isStorePageAvailable) {
				confirmationDialogData.confirmButtonCaption =
					'Go to extension store page';
				confirmHandler = (): void => {
					this.#browserExtensionService.visitStore();
				};
			} else {
				confirmationDialogData.content = promotionButNotInstalled;
				confirmationDialogData.confirmButtonCaption =
					confirmationDialogData.cancelButtonCaption;
				confirmationDialogData.showCancelButton = false;
				confirmHandler = (): void => {
					this.#manualTestService.showOverlay = true;
				};
			}
		} else {
			confirmationDialogData.content = promotionButNotChrome;
			confirmationDialogData.confirmButtonCaption = 'Cancel';
		}

		const dialogRef = this.#dialog.open(ConfirmationDialogNewComponent, {
			disableClose: true,
			width: '500px',
			panelClass: 'manual-test-dialog',
			data: confirmationDialogData
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				confirmHandler();
			} else {
				this.#manualTestService.showOverlay = true;
			}
		});
	}

	infoMenuClick(): void {
		this.#headerAnalyticsService.trackOnClickInfoMenu({});
	}

	sealightsApiReferenceClick(): void {
		this.#headerAnalyticsService.trackOnClickSealightsApiReferencePage({});
	}

	whatIsNewClick(): void {
		this.#headerAnalyticsService.trackOnClickWhatIsNewPage({});
	}
	agentReleaseNotesClicked(): void {
		this.#headerAnalyticsService.trackOnClickAgentReleaseNotes({});
	}

	sealightsLearningCenterClick(): void {
		this.#headerAnalyticsService.trackOnClickSealightsLearningCenterPage(
			{}
		);
	}

	sealightsDocumentationClick(): void {
		this.#headerAnalyticsService.trackOnClickSealightsDocumentationPage({});
	}

	supportPortalClick(): void {
		this.#headerAnalyticsService.trackOnClickSupportPortalPage({});
	}

	supportClick(): void {
		this.dialogConfig.data = {
			title:
				this.#applicationConfig?.messages?.support?.title ?? '[Title]',
			content:
				this.#applicationConfig?.messages?.support?.content ??
				'[Content]'
		};
		this.#dialog.open(GenericDialogComponent, this.dialogConfig);
	}

	productTourClick(): void {
		this.#userPilotService.triggerRouteExperience();
		this.#headerAnalyticsService.trackOnClickProductTourPage({});
	}

	externalLinkClick(name: string): void {
		this.#trackClickExternalLink(name);
	}

	showChangePasswordDialog(): void {
		this.dialogConfig.panelClass = 'sl-change-password-dialog';
		this.dialogConfig.disableClose = false;

		const dialogRef = this.#dialog.open(
			ChangePasswordDialogComponent,
			this.dialogConfig
		);

		dialogRef.updatePosition({
			top: '0',
			right: '0'
		});
	}

	toggleHideSuperuserMessages(): void {
		toggleHideSuperuserMessagesFlag();
		this.hideSuperuserMessages = getHideSuperuserMessagesFlag();
	}

	privacyPolicyClick(): void {
		this.#headerAnalyticsService.trackOnClickPrivacyPolicyPage({});
	}

	termsOfUseClick(): void {
		this.#headerAnalyticsService.trackOnClickTermsOfUsePage({});
	}

	logoutFromImpersonatedUser(): void {
		this.#store.dispatch(GlobalStoreActions.globalStoreSetLoginAsUser(null));
		setTimeout(() => location.reload());
	}

	logout(): void {
		void this.#authService.logout()
			.pipe(
				take(1)
			)
			.subscribe();
	}

	#getDeepRouteData(route: ActivatedRoute = this.#activatedRoute, data = {}): Data {
		data = { ...data, ...route.snapshot.data };
		if (route.firstChild) {
			return this.#getDeepRouteData(route.firstChild, data);
		}
		return data;
	}

	// TODO: this should be elsewhere
	#onRouteChange(): void {
		const routeData = this.#getDeepRouteData();
		this.#userPilotService.setCurrentExperience(
			routeData.userPilotExperience as UserPilotExperience,
			this.#userPilotFeatureSignalIsOn$()
		);
	}

	#trackClickExternalLink(name: string): void {
		void this.#headerAnalyticsService.trackClickExternalLink(() => ({
			name
		}));
	}
}
